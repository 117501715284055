/* eslint-disable */
const addValueToFormData = (formData, value, key, log = false) => {
  log && console.log('val.val', key, value)
  formData.append(key, value)
}

const addObjectToFormData = (formData, obj, key, log = false) => {
  for (const property in obj) {
    if (typeof obj[property] === 'array') {
      log && console.log('obj.arr', `${key}[${property}]`, obj[property], typeof obj[property])
      addArrayToFormData(formData, obj[property], `${key}[${property}]`, log)
    } else if (typeof obj[property] === 'object') {
      log && console.log(
        'obj.obj',
        `${key}[${property}]`,
        obj[property],
        typeof obj[property],
      )
      addObjectToFormData(formData, obj[property], `${key}[${property}]`, log)
    } else {
      log && console.log('obj.val', `${key}[${property}]`, property, typeof obj[property])
      addValueToFormData(formData, obj[property], `${key}[${property}]`, log)
    }
  }
}

const addArrayToFormData = (formData, array, key, log = false) => {
  array.forEach((el, index) => {
    if (typeof el === 'array') {
      log && console.log('arr.arr', `${key}[${index}]`, el)
      addArrayToFormData(formData, el, `${key}[${index}]`, log)
    } else if (typeof el === 'object') {
      log && console.log('arr.obj', `${key}[${index}]`, el)
      addObjectToFormData(formData, el, `${key}[${index}]`, log)
    } else {
      log && console.log('arr.val', key, el)
      addValueToFormData(formData, el, key, log)
    }
  })
}

export { addObjectToFormData, addArrayToFormData }
