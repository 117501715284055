<template>
  <div class="fortune-wheel-sector-form">
    <HeadSection
      :title="currentWheelId ? `Item ID ${currentSectorId}` : 'Create fortune wheel'"
      :breadcrumbs="breadcrumbs"
      permissionOfSection="fortune_wheel"
    >
    <template #actions v-if="!isOnlyView">
      <a-col :span="14" class="d-flex justify-content-end">
        <div class="d-flex">
          <a-button
            v-if="!isOnlyView"
            :disabled="loading"
            :loading="loading"
            type="primary"
            size="large"
            @click="actionSectorForm"
          >
            <span class="px-2">{{ isEditSector ? 'Save' : 'Add'}}</span>
          </a-button>
        </div>
      </a-col>
    </template>
    </HeadSection>
    <MainContainer :loading="userLoading" permissionOfSection="fortune_wheel">
      <span class="d-block mb-3 font-weight-bold font-size-20">General info</span>
      <a-form-model :model="form" :rules="rules" ref="form">
        <a-row class="d-flex">
          <a-form-model-item
            label="Modal image"
            prop="image"
            :colon="false"
            style="max-width: 166px"
            class="mr-5"
          >
            <div class="fortune-wheel-sector-form__image-container">
              <a-tooltip
                placement="top"
                arrow-point-at-center
              >
                <template v-if="form.imageUrl" slot="title">
                  <span>File size 120 x 120 px<br />(jpg, jpeg, png, webp)</span>
                </template>
                <a-upload
                  list-type="picture-card"
                  action="#"
                  class="avatar-uploader d-flex justify-content-center"
                  :show-upload-list="false"
                  :before-upload="beforeUpload"
                  @change="imgChange"
                  :disabled="loading || imageLoading || isOnlyView"
                >
                  <img v-if="form.imageUrl" :src="form.imageUrl" alt="banner image" style="width: 150px; height: 150px; border-radius: 4px;" />
                  <div v-else>
                    <a-icon :type="loading ? 'loading' : 'inbox'" class="text-primary" style="font-size: 48px" />
                    <div class="ant-upload-text" style="color: #8C8C8C; font-weight: 300; font-size: 11px;">
                      File size 120 x 120 px<br/> ( jpg, jpeg, png, webp )
                    </div>
                  </div>
                </a-upload>
              </a-tooltip>
            </div>
          </a-form-model-item>
          <a-form-model-item prop="chance" :colon="false" class="label-with-tooltip" style="max-width: 200px">
            <template #label>
              <div>
                <span class="mr-2 mb-0 font-weight-bold" style="line-height: initial;">Chance</span>
                <a-tooltip
                  placement="top"
                  arrow-point-at-center
                  :overlayStyle="{ 'white-space': 'normal', 'min-width': '350px' }"
                  :get-popup-container="getPopupContainer"
                >
                  <template slot="title">
                    <span class="font-weight-light">Enter the probability (0-100) of this sector being selected on the Fortune Wheel. Ensure the total of all sectors equals 100. We will recalculate as needed. Adjust this value to change the likelihood of landing on this sector</span>
                  </template>
                  <a-icon class="d-flex" type="info-circle" />
                </a-tooltip>
              </div>
            </template>
            <a-input-number
              v-model="form.chance"
              style="width: 180px;"
              placeholder="Chance"
              size="large"
              type="number"
              :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
              :max="maximumAvailableChance"
              :disabled="loading || maximumAvailableChance === 0 || isOnlyView"
            />
            <div v-if="!isOnlyView">
              <span
                v-if="maximumAvailableChance === 0"
                style="width: max-content;"
                class="d-flex text-default"
              >
                The current sum of the sectors chances is already equal to 100
              </span>
              <div
                v-else
                class="d-flex"
                :class="maxAvailableChanceForCurrentSector >= 0 ? 'text-default' : 'text-brown'"
                style="width: max-content; transition: color .5s ease;"
              >
                <span v-if="maxAvailableChanceForCurrentSector >= 0">Maximum chance available: {{ maximumAvailableChance }}</span>
                <span v-else>Maximum chances already set</span>
              </div>
            </div>
          </a-form-model-item>
        </a-row>
        <a-row class="d-flex mb-3">
          <div class="d-flex flex-column">
            <span class="mb-1 font-weight-bold" style="line-height: initial;">Sector type</span>
            <a-form-model-item prop="type" :colon="false" class="label-with-tooltip">
              <a-radio-group v-model="form.type" :disabled="isOnlyView" class="d-flex" style="gap: 20px;">
                <a-radio
                  v-for="type in availableSectorTypes"
                  :key="type.value"
                  :value="type.value"
                  class="d-flex align-items-center"
                >
                  <span class="d-flex align-items-center" style="gap: 5px;">
                    {{ type.name }}
                    <a-tooltip
                      placement="top"
                      arrow-point-at-center
                      :get-popup-container="getPopupContainer"
                    >
                      <template slot="title">
                        <span class="font-weight-light">
                          {{ type.info }}
                        </span>
                      </template>
                      <a-icon class="d-flex" type="info-circle" />
                    </a-tooltip>
                  </span>
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <div v-if="form.type === 'free_bet'" class="d-flex flex-wrap" style="gap: 15px;">
              <a-form-model-item
                label="Freebet ID"
                prop="data.free_bet_id"
                :rules="[{ required: true, message: 'Freebet id is required', trigger: ['blur', 'change'] }]"
                :colon="false"
              >
                <a-input
                  v-model="form.data.free_bet_id"
                  style="width: 200px"
                  placeholder="Freebet ID"
                  size="large"
                  :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
                  :disabled="loading || isOnlyView"
                  @keypress="(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }"
                />
              </a-form-model-item>
              <a-form-model-item
                label="Freebet tag"
                prop="data.free_bet_tag"
                :rules="[{ required: true, message: 'Freebet tag is required', trigger: ['blur', 'change'] }]"
                :colon="false"
              >
                <a-input
                  v-model="form.data.free_bet_tag"
                  style="width: 200px"
                  placeholder="Freebet tag"
                  size="large"
                  :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
                  :disabled="loading || isOnlyView"
                />
              </a-form-model-item>
              <a-form-model-item
                label="Denomination"
                prop="data.denomination"
                :rules="[{ required: true, message: 'Denomination is required', trigger: ['blur', 'change'] }]"
                :colon="false"
              >
                <a-input
                  v-model="form.data.denomination"
                  style="width: 100%;"
                  placeholder="500"
                  size="large"
                  type="number"
                  :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
                  :disabled="loading || isOnlyView"
                />
              </a-form-model-item>
            </div>
            <div v-if="form.type === 'promo_code'" class="d-flex flex-wrap" style="gap: 15px;">
              <a-form-model-item
                label="Promo code value"
                prop="data.promo_code"
                :rules="[{ required: true, message: 'Promo code is required', trigger: ['blur', 'change'] }]"
                :colon="false"
              >
                <a-input
                  v-model="form.data.promo_code"
                  style="width: 350px"
                  placeholder="Promo code"
                  size="large"
                  :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
                  :disabled="loading || isOnlyView"
                >
                  <a-icon
                    slot="addonBefore"
                    type="copy"
                    class="font-size-18"
                    :class="form.data.promo_code.length ? 'text-primary cursor-pointer' : 'text-default cursor-default'"
                    @click="copy(form.data.promo_code, 'Promo code copied to clipboard')"
                  />
                </a-input>
              </a-form-model-item>
            </div>
          </div>
        </a-row>
        <span class="d-block mb-3 font-weight-bold font-size-20">Languages</span>
        <a-row v-if="!loading">
          <a-tabs
            v-model="activeLanguage"
            :animated="{ inkBar: true, tabPane: false }"
            @change="changeActiveLanguageTab"
          >
            <a-tab-pane
              v-for="(langTab, languageIndex) in availableLanguages"
              :key="languageIndex"
              force-render
            >
              <template #tab>
                <div>
                  <span :class="{'text-danger' : form.languages[languageIndex].error_validate}">
                    {{ langTab.name }}
                  </span>
                  <a-badge
                    v-if="langTab.is_default"
                    count="Default"
                    :number-style="{
                      backgroundColor: '#fff',
                      color: '#999',
                      boxShadow: '0 0 0 1px #d9d9d9 inset',
                    }"
                    style="transform: scale(0.8);"
                  />
                </div>
              </template>
              <div class="d-flex justify-content-between align-items-center">
                <span class="d-block mb-3 font-weight-bold font-size-16">Sector Info</span>
                <span
                  v-if="!isOnlyView && availableLanguages.length > 1"
                  :class="disableCopy ? 'text-default cursor-default' : 'text-primary cursor-pointer underline'"
                  @click="copyToOtherLanguages(languageIndex)"
                >
                  Copy to other languages
                </span>
              </div>
              <div class="d-flex flex-wrap" style="max-width: 665px; gap: 15px;">
                <Readonly
                  :show="isOnlyView"
                  title="Name"
                  :value="form.languages[languageIndex].name"
                  :required="true"
                  style="width: 300px;"
                  class="mb-4"
                >
                  <a-form-model-item
                    style="width: 300px"
                    label="Name"
                    :key="`name${languageIndex}`"
                    :prop="`languages.${languageIndex}.name`"
                    :colon="false"
                    :rules="[
                      { required: true, message: 'Name is required', trigger: ['blur', 'change'] },
                      { max: 11, message: 'Maximum 11 characters' }
                    ]"
                  >
                    <a-input
                      v-model="form.languages[languageIndex].name"
                      placeholder="Name"
                      size="large"
                      :disabled="loading"
                      @focus="clearError(languageIndex)"
                      @change="disableCopy = false"
                    />
                  </a-form-model-item>
                </Readonly>
                <Readonly
                  :show="isOnlyView && form.languages[languageIndex].description"
                  title="Description"
                  :value="form.languages[languageIndex].description"
                  class="mb-4"
                  style="width: 350px"
                >
                  <a-form-model-item
                    label="Description"
                    :colon="false"
                    :prop="`languages.${languageIndex}.description`"
                    style="width: 350px"
                    :rules="[
                      { max: 21, message: 'Maximum 21 characters' }
                    ]"
                  >
                    <a-input
                      v-model="form.languages[languageIndex].description"
                      placeholder="Description"
                      size="large"
                      :disabled="loading"
                      @change="disableCopy = false"
                    />
                  </a-form-model-item>
                </Readonly>
              </div>

              <span class="d-block mb-3 font-weight-bold font-size-16">Sector modal window</span>
              <div class="d-flex flex-wrap" style="max-width: 665px; gap: 15px;">
                <Readonly
                  :show="isOnlyView"
                  title="Name"
                  :value="form.languages[languageIndex].modal_name"
                  :required="true"
                  style="width: 300px"
                  class="mb-4"
                >
                  <a-form-model-item
                    style="width: 300px"
                    label="Name"
                    :key="`name${languageIndex}`"
                    :prop="`languages.${languageIndex}.modal_name`"
                    :colon="false"
                    :rules="[{ required: true, message: 'Name is required', trigger: ['blur', 'change'] }]"
                  >
                    <a-input
                      v-model="form.languages[languageIndex].modal_name"
                      placeholder="Name"
                      size="large"
                      :disabled="loading"
                      @focus="clearError(languageIndex)"
                      @change="disableCopy = false"
                    />
                  </a-form-model-item>
                </Readonly>
                <Readonly
                  :show="isOnlyView && form.languages[languageIndex].modal_description"
                  title="Description"
                  :value="form.languages[languageIndex].modal_description"
                  class="mb-4"
                  style="width: 350px"
                >
                  <a-form-model-item
                    label="Description"
                    prop="lang"
                    :colon="false"
                    style="width: 350px"
                  >
                    <a-input
                      v-model="form.languages[languageIndex].modal_description"
                      placeholder="Description"
                      size="large"
                      :disabled="loading"
                      @change="disableCopy = false"
                    />
                  </a-form-model-item>
                </Readonly>
              </div>

              <a-tabs
                v-model="activeButton"
                type="card"
                :animated="{ inkBar: true, tabPane: false }"
                @change="changeActiveButtonTab($event, languageIndex)"
              >
                <a-tab-pane
                  v-for="(button, buttonIdx) in form.languages[languageIndex].buttons"
                  :key="buttonIdx"
                  force-render
                  class="123"
                >
                  <template #tab>
                    <span :class="{'text-danger' : form.languages[languageIndex].buttons[buttonIdx].error_validate}">
                      {{ `${upperFirstLetter(button.class)} button` }}
                    </span>
                  </template>
                  <div class="d-flex flex-column" :ref="`button-container-${languageIndex}-${buttonIdx}`">
                    <div class="d-flex flex-wrap" style="gap: 15px; max-width: 665px;">
                      <a-form-model-item
                        label="Select type"
                        :colon="false"
                      >
                        <UniversalSelect
                          v-model="form.languages[languageIndex].buttons[buttonIdx].type"
                          :displayOptionsAtBottom="true"
                          :options="allButtonTypes"
                          placeholder="Select type"
                          style="width: 250px;"
                          :readonly="isOnlyView ? !!form.languages[languageIndex].buttons[buttonIdx].type : false"
                          :disabled="loading || isOnlyView"
                          @change="disableCopy = false"
                        />
                      </a-form-model-item>
                      <div v-if="form.languages[languageIndex].buttons[buttonIdx].type">
                        <a-form-model-item
                          label="Button name"
                          :key="`name${buttonIdx}`"
                          :prop="`languages.${languageIndex}.buttons.${buttonIdx}.name`"
                          :colon="false"
                          :rules="[
                            { required: true, message: 'Button name is required', trigger: ['blur', 'change'] },
                            { max: 18, message: 'Maximum 18 characters' }
                          ]"
                        >
                          <a-input
                            v-model="form.languages[languageIndex].buttons[buttonIdx].name"
                            style="width: 250px"
                            placeholder="Button name"
                            size="large"
                            :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
                            :disabled="loading || isOnlyView"
                            @focus="clearError(languageIndex, buttonIdx)"
                            @change="disableCopy = false"
                          />
                        </a-form-model-item>
                      </div>
                    </div>
                    <div v-if="form.languages[languageIndex].buttons[buttonIdx].type === 'deeplink' || form.languages[languageIndex].buttons[buttonIdx].type === 'link'">
                      <div class="d-flex align-items-center">
                        <a-form-model-item
                          label="Template"
                          :colon="false"
                        >
                          <UniversalSelect
                            v-model="selectedTemplate"
                            style="width: 300px;"
                            :options="templates"
                            placeholder="Select template"
                            :displayOptionsAtBottom="true"
                            :showSearch="true"
                            :readonly="isOnlyView ? !!selectedTemplate : false"
                            :disabled="loading || isOnlyView"
                            @change="changeTemplate(languageIndex, buttonIdx, button), disableCopy = false"
                          />
                        </a-form-model-item>
                        <a-button
                          v-if="!isOnlyView && selectedTemplate"
                          :disabled="loading"
                          type="link"
                          size="large"
                          class="ml-3 px-0"
                          style="width: 40px; height: 40px;"
                          @click="getSelectedTemplate(selectedTemplate)"
                        >
                          <span class="cursor-pointer">Edit</span>
                        </a-button>
                      </div>
                      <a-form-model-item
                        :ref="`link-${languageIndex}-${buttonIdx}`"
                        :key="form.languages[languageIndex].buttons[buttonIdx].type === 'deeplink' ? `deeplink${buttonIdx}` : `link${buttonIdx}`"
                        :prop="`languages.${languageIndex}.buttons.${buttonIdx}.data.link`"
                        :colon="false"
                        :rules="[{ required: true, message: 'Source is required', trigger: ['blur', 'change'] }]"
                        class="label-with-tooltip"
                      >
                        <template #label>
                          <div>
                            <span class="mr-2 mb-0 font-weight-bold" style="line-height: initial;">Source</span>
                            <a-tooltip
                              placement="top"
                              arrow-point-at-center
                              :overlayStyle="{ 'white-space': 'normal', 'min-width': '450px' }"
                              :get-popup-container="getPopupContainer"
                            >
                              <template slot="title">
                                <span class="font-weight-light" v-html="sourceInfoText" />
                              </template>
                              <a-icon class="d-flex" type="info-circle" />
                            </a-tooltip>
                          </div>
                        </template>
                        <div class="d-flex">
                          <a-input
                            v-model="form.languages[languageIndex].buttons[buttonIdx].data.link"
                            :ref="`source-${languageIndex}-${buttonIdx}`"
                            class="source-link"
                            :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView && form.languages[languageIndex].buttons[buttonIdx].data.link }"
                            :style="`width: ${form.languages[languageIndex].buttons[buttonIdx].sourceWidth}px`"
                            placeholder="Source"
                            size="large"
                            allow-clear
                            :disabled="loading || isOnlyView"
                            @focus="clearError(languageIndex, buttonIdx)"
                            @blur="setSourceWidth(languageIndex, buttonIdx)"
                            @change="checkSource(), disableCopy = false"
                          >
                            <a-icon
                              slot="addonBefore"
                              type="copy"
                              class="font-size-18"
                              :class="form.languages[languageIndex].buttons[buttonIdx].data.link.length ? 'text-primary cursor-pointer' : 'text-default cursor-default'"
                              @click="copy(form.languages[languageIndex].buttons[buttonIdx].data.link, 'Source link copied to clipboard')"
                            />
                          </a-input>
                          <a-button
                            v-if="!isOnlyView && form.languages[languageIndex].buttons[buttonIdx].data.link.length && !form.languages[languageIndex].buttons[buttonIdx].template_exists"
                            :disabled="loading"
                            type="primary"
                            size="large"
                            class="ml-3"
                            @click="showTemplateModal({ name: '', source: form.languages[languageIndex].buttons[buttonIdx].data.link, disabled_source: true })"
                          >
                            Save as template
                          </a-button>
                        </div>
                      </a-form-model-item>
                      <div v-if="form.languages[languageIndex].buttons[buttonIdx].show_result_url" class="d-flex mb-3" style="gap: 10px;">
                        <span class="font-weight-bold">Result URL: </span>
                        <div class="d-flex" v-if="form.languages[languageIndex].buttons[buttonIdx].data.link.includes('{scheme}')">
                          {{ form.languages[languageIndex].buttons[buttonIdx].data.link.split('{scheme}')[0] }}
                          <a-tooltip
                            placement="top"
                            arrow-point-at-center
                            :overlayStyle="{ 'white-space': 'normal' }"
                            :get-popup-container="getPopupContainer"
                          >
                            <template slot="title">
                              <span class="font-weight-bold">Wheel schemes:</span><br>
                              <div
                                v-for="(scheme, index) in availableSchemes"
                                :key="`${scheme}-${index}`"
                              >
                                <span
                                  class="font-weight-light"
                                >
                                  {{ scheme }}
                                </span>
                                <br v-if="index !== availableSchemes.length - 1">
                              </div>
                            </template>
                            <span class="d-flex font-weight-bold text-primary ml-1 cursor-pointer">{scheme}</span>
                          </a-tooltip>
                          <span v-if="form.languages[languageIndex].buttons[buttonIdx].data.link.includes('{promocode}')">
                            {{ form.languages[languageIndex].buttons[buttonIdx].data.link.split('{scheme}')[1].split('{promocode}')[0] }}{{ form.data.promo_code }}{{ form.languages[languageIndex].buttons[buttonIdx].data.link.split('{scheme}')[1].split('{promocode}')[1] }}
                          </span>
                          <span v-else>
                            {{ form.languages[languageIndex].buttons[buttonIdx].data.link.split('{scheme}')[1] }}
                          </span>
                        </div>
                        <div v-else>{{ form.languages[languageIndex].buttons[buttonIdx].result_url }}</div>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </a-tab-pane>
          </a-tabs>
        </a-row>
      </a-form-model>
      <div v-if="!isOnlyView && isEditSector && deletePermission" class="mt-5">
        <div
          class="d-flex align-items-center text-danger cursor-pointer hover-underline"
          style="width: fit-content;"
          @click="toggleShowDeleteModal(true)"
        >
          <a-icon type="delete" class="mr-2" />
          <span>Delete sector</span>
        </div>
      </div>
    </MainContainer>
    <TemplateModal
      v-if="openTemplateModal"
      :show="openTemplateModal"
      :selectedTemplate="editTemplateItem"
      @create="createTemplate"
      @edit="editTemplate"
      @delete="deleteTemplate"
      @close="closeTemplateModal"
    />
    <DefaultModal
      :show="showDeleteModal"
      title="Delete sector"
      text="Are you sure you want to delete this sector?"
      description="All associated data will be lost"
      :actionButton="{
        text: 'Delete',
        type: 'danger',
      }"
      :loading="loading"
      @close="toggleShowDeleteModal"
      @action="removeSector"
    />
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import HeadSection from '@/components/main/headSection'
import MainContainer from '@/components/main/mainContainer'
import UniversalSelect from '@/components/custom/filters/universalSelect.vue'
import { addArrayToFormData, addObjectToFormData } from '@/services/utils/formData.util.js'
import { deleteSector } from '@/views/apps/fortune-wheel/composables/useDeleteSector.js'
import { mapState } from 'vuex'
import { getErrorMessage } from '@/views/apps/fortune-wheel/composables/useGetErrorMessage.js'
import { getTextWidth } from '@/views/apps/fortune-wheel/composables/useMeasureText.js'
import TemplateModal from '@/views/apps/fortune-wheel/components/TemplateModal'
import DefaultModal from '@/components/custom/modals/defaultModal'
import { copyToClipboard } from '@/composables/copy/copyToClipboard.js'
import Readonly from '@/components/custom/readonlyMode/readonly.vue'

const wheelApiUrl = process.env.VUE_APP_WHEEL_API_URL

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  props: {
    createPermission: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: Boolean,
      default: false,
    },
    deletePermission: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeadSection,
    MainContainer,
    UniversalSelect,
    TemplateModal,
    DefaultModal,
    Readonly,
  },
  data: () => ({
    currentWheelId: null,
    currentWheel: null,
    currentSectorId: null,
    activeLanguage: 0,
    activeButton: 0,
    form: {
      image: null,
      imageUrl: '',
      chance: undefined,
      type: 'generic',
      data: {
        free_bet_id: undefined,
        free_bet_tag: '',
        denomination: undefined,
        promo_code: '',
      },
      languages: [],
    },
    availableLanguages: [],
    selectedLanguage: null,
    availableSchemes: [],
    allButtonTypes: [
      { name: 'Link', value: 'link' },
      { name: 'Deeplink', value: 'deeplink' },
      { name: 'Close', value: 'close' },
    ],
    allSectorTypes: [
      { name: 'Generic', value: 'generic', info: 'For general-purpose settings and links without a specific designation' },
      { name: 'Freebet', value: 'free_bet', info: 'A sector type that grants a free bet bonus to authorized users' },
      { name: 'Promo code', value: 'promo_code', info: 'Provides a promo code upon registration for new users, which grants a FreeBet' },
      { name: 'Re-spin', value: 're_spin', info: 'Allows the user to spin the wheel again' },
    ],
    maximumAvailableChance: 100,
    sourceWidth: 665,
    disableCopy: false,
    templates: [],
    selectedTemplate: undefined,
    openTemplateModal: false,
    editTemplateItem: null,
    showDeleteModal: false,
    imageLoading: false,
    loading: false,
  }),
  watch: {
    editPermission: {
      handler(val) {
        if (!val) {
          if (this.currentSectorId && this.$route.name === 'edit-sector-for-fortune-wheel') {
            this.$router.push(`/fortune-wheel/${this.$route?.params?.id}/view-sector/${this.$route.params?.sectorId}`)
          }
        }
      },
      deep: true,
    },
    createPermission: {
      handler(val) {
        if (!val) {
          if (this.currentSectorId && this.$route.name === 'create-sector-for-fortune-wheel') {
            this.$router.push(`/fortune-wheel/${this.$route?.params?.id}`)
          }
        }
      },
      deep: true,
    },
    'form.type': {
      handler(val) {
        this.checkSource()
        if (val !== 'promo_code') {
          for (let i = 0; i < this.form.languages.length; i++) {
            for (let b = 0; b < this.form.languages[i].buttons; b++) {
              if (this.form.languages[i].buttons[b].data.link.includes('?promocode=')) {
                const originalUrl = this.form.languages[i].buttons[b].data.link.split('?promocode=')[0]
                if (originalUrl?.length) {
                  this.form.languages[i].buttons[b].data.link = originalUrl
                }
              }
            }
          }
        } else {
          for (let i = 0; i < this.form.languages.length; i++) {
            for (let b = 0; b < this.form.languages[i].buttons; b++) {
              this.$refs[`link-${i}-${b}`][0].clearValidate()
              if (!this.form.languages[i].buttons[b].data.link.length) {
                return
              }
              // if (!this.form.languages[i].buttons[b].data.link.includes('?promocode=') && !this.form.languages[i].buttons[b].data.link.includes('{promocode}')) {
              //   const originalUrl = this.form.languages[i].buttons[b].data.link.split('?promocode=')[0]
              //   this.form.languages[i].buttons[b].data.link = `${originalUrl}?promocode={promocode}`
              // }
            }
          }
        }
      },
      deep: true,
    },
    'form.data.promo_code': {
      handler(val) {
        if (this.form.type !== 'promo_code') {
          return
        }
        this.checkSource()
        for (let i = 0; i < this.form.languages.length; i++) {
          if (!this.form.languages[i].buttons[0].data.link.length) {
            return
          }
          // for (let b = 0; b < this.form.languages[i].buttons.length; b++) {
          //   if (this.form.languages[i].buttons[b].data.link.includes('?promocode=')) {
          //     const originalUrl = this.form.languages[i].buttons[b].data.link.split('?promocode=')[0]
          //     this.form.languages[i].buttons[b].data.link = `${originalUrl}?promocode={promocode}`
          //   } else {
          //     this.form.languages[i].buttons[b].data.link = `${this.form.languages[i].buttons[b].data.link}?promocode={promocode}`
          //   }
          // }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['user']),
    userLoading() {
      return this.$store.state?.user?.loading || false
    },
    breadcrumbs() {
      return [
        { name: 'Fortune wheel', link: '/fortune-wheel' },
        { name: this.currentWheel?.name, link: `/fortune-wheel/${this.currentWheelId}` },
        { name: this.isOnlyView ? 'View sector' : this.isEditSector ? 'Edit sector' : 'Create sector', link: undefined },
      ]
    },
    rules() {
      const currentRules = {
        image: [{ required: true, message: 'Image is required', trigger: ['change'] }],
        chance: [{ required: true, message: 'Chance value is required', trigger: ['blur', 'change'] }],
      }
      return currentRules
    },
    isOnlyView() {
      return this.$route.name === 'view-sector-for-fortune-wheel'
    },
    isEditSector() {
      return !!this.currentSectorId
    },
    maxAvailableChanceForCurrentSector() {
      return this.form.chance ? this.maximumAvailableChance - this.form.chance : this.maximumAvailableChance
    },
    wheelForAuthorizedUsers() {
      return this.currentWheel?.is_auth_only
    },
    availableSectorTypes() {
      return this.wheelForAuthorizedUsers
        ? this.allSectorTypes.filter(type => type.value !== 'promo_code')
        : this.allSectorTypes.filter(type => type.value !== 'free_bet')
    },
    sourceInfoText() {
      return `
        <div class="d-flex flex-column">
          <span>Available Query Parameters (values will be automatically substituted):</span>
          <ul style="margin: 5px 0; padding-inline-start: 15px;">
            <li>{scheme}: The platform name</li>
            ${this.form.type === 'promo_code'
              ? '<li>{promocode}: The promo code value tied to the sector</li>'
              : ''
            }
          </ul>
          <span>
            ${this.form.type === 'promo_code'
              ? 'Example link: {scheme}registration?promocode={promocode}'
              : 'Example link: {scheme}actionList'}
          </span>
        </div>
      `
    },
  },
  methods: {
    getPopupContainer() {
      return document.body
    },
    beforeUpload(file) {
      const IncorrectImageFormat = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/webp'
      if (!IncorrectImageFormat) {
        this.$message.error('You can only upload JPG, JPEG, PNG or WEBP file!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!')
        return false
      }
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = (err) => {
          const inProportions = Math.round(10 * (img.width / img.height)) / 10 === 1
          if (!inProportions) {
            this.$message.error('Image must be in correct proportions - 14 : 5 (e.g. 120 x 120 px)')
            reject(err)
          } else {
            resolve()
          }
        }
      })
    },
    imgChange(info) {
      if (info.file.status === 'uploading') {
        this.imageLoading = true
        return
      }
      this.form.image = info.file.originFileObj
      getBase64(info.file.originFileObj, imageUrl => {
        this.form.imageUrl = imageUrl
        this.imageLoading = false
        this.$refs.form.validateField('image')
      })
    },
    copy(value, message) {
      copyToClipboard(this, value, message)
    },
    checkSource() {
      this.$nextTick(() => {
        this.templateIncludesSource(this.activeLanguage, this.activeButton, this.form.languages[this.activeLanguage].buttons[this.activeButton].data?.link)
        this.form.languages.forEach((lang, languageIndex) => {
          this.form.languages[languageIndex].buttons.forEach((button, buttonIndex) => {
            if (button.type === 'link' || button.type === 'deeplink') {
              if (button.data.link.includes('{scheme}') || button.data.link.includes('{promocode}')) {
                let url = ''
                if (button.data.link.includes('{promocode}') && this.form.type === 'promo_code') {
                  button.show_result_url = true
                  url = button.data.link.replace('{promocode}', this.form.data.promo_code ? this.form.data.promo_code : '')
                } else {
                  url = button.data.link
                }
                if (button.data.link.includes('{scheme}')) {
                  button.show_result_url = true
                }
                button.result_url = url
              } else {
                button.show_result_url = false
                button.result_url = ''
              }
              this.setSourceWidth(languageIndex, buttonIndex)
            }
          })
        })
      })
    },
    setSourceWidth(languageIndex, buttonIdx) {
      if (this.$refs[`source-${languageIndex}-${buttonIdx}`]) {
        const containerWidth = this.$refs[`button-container-${languageIndex}-${buttonIdx}`][0].clientWidth || this.sourceWidth
        const currentTextLink = `${this.form.languages[languageIndex].buttons[buttonIdx].data.link}`
        // const currentTextLink = `${this.form.languages[languageIndex].buttons[buttonIdx].data.link}${this.form.type === 'promo_code' ? '?promocode={promocode}' : ''}`
        const textWidth = Math.round(getTextWidth(currentTextLink, '16px'))
        const paddings = 20
        const copyIconWidh = 41
        const saveTemplateButton = 160

        const maxInputWidth = Math.round(containerWidth) - saveTemplateButton + copyIconWidh + paddings + textWidth
        if (textWidth > maxInputWidth) {
          this.$refs[`source-${languageIndex}-${buttonIdx}`][0].$el.style.width = `${maxInputWidth}px`
        } else {
          this.$refs[`source-${languageIndex}-${buttonIdx}`][0].$el.style.width = `${textWidth}px`
        }
      }
    },
    templateIncludesSource(languageIndex, buttonIndex, source) {
      const isIncludes = this.templates.find(template => template.source === source)
      if (!isIncludes) {
        this.form.languages[languageIndex].buttons[buttonIndex].template_exists = false
        this.selectedTemplate = undefined
      } else {
        this.form.languages[languageIndex].buttons[buttonIndex].template_exists = true
        this.selectedTemplate = isIncludes?.value
      }
      return isIncludes
    },
    changeActiveLanguageTab(index) {
      this.clearError(index)
      this.$nextTick(() => {
        this.templateIncludesSource(this.activeLanguage, this.activeButton, this.form.languages[this.activeLanguage].buttons[this.activeButton].data.link)
      })
    },
    changeActiveButtonTab(buttonIndex, languageIndex) {
      this.clearError(languageIndex, buttonIndex)
      this.templateIncludesSource(languageIndex, buttonIndex, this.form.languages[languageIndex].buttons[buttonIndex].data.link)
    },
    clearError(languageIndex, buttonIndex = undefined) {
      if (buttonIndex !== undefined) {
        this.form.languages[languageIndex].buttons[buttonIndex].error_validate = false
        return
      }
      this.form.languages[languageIndex].error_validate = false
    },
    async copyToOtherLanguages(index) {
      if (this.disableCopy) {
        return
      }
      this.disableCopy = true
      for (let i = 0; i < this.availableLanguages.length; i++) {
        this.form.languages[i].name = `${this.form.languages[index].name}`
        this.form.languages[i].description = `${this.form.languages[index].description}`
        this.form.languages[i].modal_name = `${this.form.languages[index].modal_name}`
        this.form.languages[i].modal_description = `${this.form.languages[index].modal_description}`
        // eslint-disable-next-line
        this.form.languages[i].buttons = structuredClone(this.form.languages[index].buttons)
        let error = false
        await this.$refs.form.validateField(`languages.${i}.name`, async (errorMessage) => {
          if (errorMessage) {
            error = true
          }
        })
        await this.$refs.form.validateField(`languages.${i}.modal_name`, async (errorMessage) => {
          if (errorMessage) {
            error = true
          }
        })
        this.form.languages[i].error_validate = error
      }
    },
    upperFirstLetter(str) {
      if (!str) {
        return str
      }
      return str[0].toUpperCase() + str.slice(1)
    },
    checkingForTabFilling() {
      for (let i = 0; i < this.availableLanguages.length; i++) {
        this.$refs.form.validateField(`languages.${i}.name`, async (errorMessage) => {
          if (errorMessage) {
            this.form.languages[i].error_validate = true
          }
        })
        this.$refs.form.validateField(`languages.${i}.description`, async (errorMessage) => {
          if (errorMessage) {
            this.form.languages[i].error_validate = true
          }
        })
        this.$refs.form.validateField(`languages.${i}.modal_name`, async (errorMessage) => {
          if (errorMessage) {
            this.form.languages[i].error_validate = true
          }
        })
        this.$refs.form.validateField(`languages.${i}.modal_description`, async (errorMessage) => {
          if (errorMessage) {
            this.form.languages[i].error_validate = true
          }
        })
        // buttons
        this.form.languages[i].buttons.forEach((button, buttonIndex) => {
          if (!button.type) {
            return
          }
          switch (button.type) {
            case 'close':
              this.$refs.form.validateField(`languages.${i}.buttons.${buttonIndex}.name`, async (errorMessage) => {
                if (errorMessage) {
                  this.form.languages[i].error_validate = true
                  this.form.languages[i].buttons[buttonIndex].error_validate = true
                }
              })
              break
            default: {
              this.$refs.form.validateField(`languages.${i}.buttons.${buttonIndex}.name`, async (errorMessage) => {
                if (errorMessage) {
                  this.form.languages[i].error_validate = true
                  this.form.languages[i].buttons[buttonIndex].error_validate = true
                }
              })
              this.$refs.form.validateField(`languages.${i}.buttons.${buttonIndex}.data.link`, async (errorMessage) => {
                if (errorMessage) {
                  this.form.languages[i].error_validate = true
                  this.form.languages[i].buttons[buttonIndex].error_validate = true
                }
              })
            }
          }
        })
      }
    },
    getFormData() {
      const formData = new FormData()

      if (this.isEditSector) {
        formData.append('_method', 'PUT')
      }
      formData.append('wheel_id', this.currentWheel.id)
      formData.append('chance', this.form.chance)
      if (typeof this.form.image === 'object') {
        formData.append('image', this.form.image)
      }
      formData.append('type', this.form.type)
      if (this.form.type === 'free_bet') {
        const data = {
          free_bet_id: this.form.data.free_bet_id,
          free_bet_tag: this.form.data.free_bet_tag,
          denomination: this.form.data.denomination,
        }
        addObjectToFormData(formData, data, 'data')
      } else if (this.form.type === 'promo_code') {
        const data = {
          promo_code: this.form.data.promo_code,
        }
        addObjectToFormData(formData, data, 'data')
      }

      const languages = []
      for (let i = 0; i < this.availableLanguages.length; i++) {
        const lang = {
          language_id: this.form.languages[i].language.id,
          name: this.form.languages[i].name,
          description: this.form.languages[i].description,
          modal_name: this.form.languages[i].modal_name,
          modal_description: this.form.languages[i].modal_description,
        }
        const buttons = []
        this.form.languages[i].buttons.forEach(button => {
          if (!button.type) {
            return
          }
          switch (button.type) {
            case 'close':
              buttons.push({
                type: button.type,
                name: button.name,
                class: button.class,
              })
              break
            default: {
              buttons.push({
                type: button.type,
                name: button.name,
                class: button.class,
                data: {
                  link: button.data.link,
                },
              })
            }
          }
        })
        if (buttons.length) {
          lang.buttons = buttons
        }
        languages.push(lang)
      }
      addArrayToFormData(formData, languages, 'languages')

      return formData
    },
    async actionSectorForm() {
      // Нужно чтобы отобразить в каких табах есть ошибки валидации
      this.checkingForTabFilling()
      //
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const data = this.getFormData()
          try {
            this.loading = true

            let response
            if (this.isEditSector) {
              const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}/sectors/${this.currentSectorId}`
              response = await apiClient.post(url, data, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
            } else {
              const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}/sectors`
              response = await apiClient.post(url, data, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
            }

            if (response?.data?.success) {
              this.$notification.success({
                message: this.isEditSector ? 'Sector updated successfully' : 'Sector added successfully',
              })
              this.backToWheel()
            } else {
              getErrorMessage(response?.data)
            }
          } finally {
            this.loading = false
          }
        } else {
          this.$notification.error({
            message: 'Please fill in all required fields',
          })
          return false
        }
      })
      return false
    },
    async removeSector() {
      try {
        this.loading = true

        const response = await deleteSector(this.currentWheelId, this.currentSectorId)
        if (response?.data?.success) {
          this.$notification.success({
            message: this.form?.languages[0]?.name ? `Sector "${this.form.languages[0].name}" removed` : 'Sector removed',
          })
          this.backToWheel()
        } else {
          this.$notification.error({
            message: 'Error',
            description: response?.data?.message || '',
          })
        }
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    async getWheelParams() {
      try {
        this.currentWheelId = this.$route.params.id
        const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}`

        const response = await apiClient.get(url)
        if (response?.data?.success) {
          this.currentWheel = response.data.data
          this.calcSumChancesWheelSectors(this.currentWheel?.sectors)
          this.setAvailableSectorLanguages(this.currentWheel?.languages)
          this.availableSchemes = response?.data?.options?.schemas || []
        }
      } catch (e) {
        this.$notification.error({
          message: e?.message ? e.message : 'Error',
        })
      }
    },
    calcSumChancesWheelSectors(sectors = []) {
      const currentSumOfSectorOdds = sectors.reduce((sum, sector) => {
        if (sector.id === Number(this.currentSectorId)) {
          return sum
        }
        return sum + sector.chance
      }, 0)
      if (currentSumOfSectorOdds >= 100) {
        this.maximumAvailableChance = 0
        this.form.chance = 0
      } else {
        this.maximumAvailableChance = 100 - currentSumOfSectorOdds
      }
    },
    setAvailableSectorLanguages(languages = []) {
      this.availableLanguages = languages.map(lang => {
        return {
          language_id: lang.language_id,
          name: lang.name,
          is_default: lang.is_default,
        }
      })
      this.form.languages = this.availableLanguages.map(lang => {
        return {
          language: { id: lang.language_id },
          name: '',
          description: '',
          modal_name: '',
          modal_description: '',
          error_validate: false,
          buttons: [
            {
              class: 'primary',
              type: undefined,
              name: '',
              error_validate: false,
              data: {
                link: '',
              },
              show_result_url: false,
              result_url: '',
              template_exists: false,
              sourceWidth: this.sourceWidth,
            },
            {
              class: 'secondary',
              type: undefined,
              name: '',
              error_validate: false,
              data: {
                link: '',
              },
              show_result_url: false,
              result_url: '',
              template_exists: false,
              sourceWidth: this.sourceWidth,
            },
          ],
        }
      })
    },
    async getSectorParams() {
      try {
        const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}/sectors/${this.currentSectorId}`

        const response = await apiClient.get(url)
        if (response?.data?.success) {
          this.setSectorParams(response.data.data)
        }
      } catch (e) {
        this.$notification.error({
          message: e?.message ? e.message : 'Error',
        })
      }
    },
    setSectorParams(sector) {
      this.form.image = sector.image
      this.form.imageUrl = sector.image
      this.form.chance = sector.chance
      this.form.type = sector.type
      this.form.data = { ...this.form.data, ...sector.data }
      sector.languages.forEach((lang, languageIndex) => {
        const indexLang = this.form.languages.findIndex(el => el.language.id === lang.language.id)
        this.form.languages.splice(indexLang, 1, {
          ...lang,
          error_validate: false,
          buttons: [
            {
              class: 'primary',
              type: lang.buttons[0]?.type ? lang.buttons[0].type : undefined,
              name: lang.buttons[0]?.name ? lang.buttons[0].name : '',
              error_validate: false,
              data: {
                link: lang.buttons[0]?.data?.link ? lang.buttons[0].data.link : '',
              },
              show_result_url: false,
              result_url: '',
              template_exists: false,
              sourceWidth: this.sourceWidth,
            },
            {
              class: 'secondary',
              type: lang.buttons[1]?.type ? lang.buttons[1].type : undefined,
              name: lang.buttons[1]?.name ? lang.buttons[1].name : '',
              error_validate: false,
              data: {
                link: lang.buttons[1]?.data?.link ? lang.buttons[1]?.data?.link : '',
              },
              show_result_url: false,
              result_url: '',
              template_exists: false,
              sourceWidth: this.sourceWidth,
            },
          ],
        })
      })
    },
    async getTemplates() {
      try {
        const url = `${wheelApiUrl}/admin/source_templates`

        const response = await apiClient.get(url)
        if (response?.data?.data) {
          this.templates = response?.data?.data.map(el => {
            return {
              name: el.name,
              value: el.id,
              source: el.source,
              disabled_source: false,
            }
          }) || []
        }
      } catch (e) {
        this.$notification.error({
          message: e?.message ? e.message : 'Error',
        })
      }
    },
    changeTemplate(languageIndex, buttonIdx, button) {
      const newSelectedTemplateIndex = this.templates.findIndex(template => template.value === Number(this.selectedTemplate))
      if (newSelectedTemplateIndex >= 0) {
        this.form.languages[languageIndex].buttons[buttonIdx].data.link = this.templates[newSelectedTemplateIndex]?.source
        // if (this.form.type === 'promo_code' && button.class === 'primary') {
        //   this.form.languages[languageIndex].buttons[buttonIdx].data.link = `${this.templates[newSelectedTemplateIndex]?.source}?promocode=${this.form.data.promo_code}`
        // } else {
        //   this.form.languages[languageIndex].buttons[buttonIdx].data.link = this.templates[newSelectedTemplateIndex]?.source
        // }
        if (this.$refs[`link-${languageIndex}-${buttonIdx}`]) {
          this.$refs[`link-${languageIndex}-${buttonIdx}`][0].clearValidate()
        }
      }
      this.$nextTick(() => {
        this.templateIncludesSource(languageIndex, buttonIdx, this.form.languages[languageIndex].buttons[buttonIdx].data.link)
      })
    },
    getSelectedTemplate(templateValue, show = true) {
      const selectedTemplate = this.templates.find(template => template.value === templateValue)
      if (show) {
        this.showTemplateModal(selectedTemplate)
      } else {
        return selectedTemplate
      }
    },
    showTemplateModal(template = null) {
      this.editTemplateItem = template
      this.openTemplateModal = true
    },
    createTemplate(template) {
      this.templates.unshift(template)
      this.$nextTick(() => {
        this.templateIncludesSource(this.activeLanguage, this.activeButton, this.form.languages[this.activeLanguage].buttons[this.activeButton].data.link)
      })
    },
    editTemplate(template) {
      const templateIndex = this.templates.findIndex(el => el.value === template.value)

      if (templateIndex >= 0) {
        this.templates.splice(templateIndex, 1, template)
        if (this.selectedTemplate === this.templates[templateIndex].value) {
          this.form.languages[this.activeLanguage].buttons[this.activeButton].data.link = this.templates[templateIndex].source
        }
      }
      this.$nextTick(() => {
        this.templateIncludesSource(this.activeLanguage, this.activeButton, this.form.languages[this.activeLanguage].buttons[this.activeButton].data.link)
      })
    },
    deleteTemplate(templateValue) {
      const templateIndex = this.templates.findIndex(el => el.value === templateValue)
      if (templateIndex >= 0) {
        if (this.selectedTemplate === templateValue) {
          this.selectedTemplate = undefined
        }
        this.templates.splice(templateIndex, 1)
      }
      this.$nextTick(() => {
        this.templateIncludesSource(this.activeLanguage, this.activeButton, this.form.languages[this.activeLanguage].buttons[this.activeButton].data.link)
      })
    },
    closeTemplateModal() {
      this.openTemplateModal = false
      this.editTemplateItem = null
    },
    toggleShowDeleteModal(status = false) {
      this.showDeleteModal = status
    },
    backToWheel() {
      this.$router.push(`/fortune-wheel/${this.currentWheel.id}`)
    },
  },
  async created() {
    this.currentWheelId = this.$route.params?.id
    this.currentSectorId = this.$route.params?.sectorId

    try {
      this.loading = true
      const requests = [this.getTemplates()]

      if (this.currentWheelId) {
        requests.push(this.getWheelParams())
        await Promise.all(requests)
      }
      if (this.currentSectorId) {
        await this.getSectorParams()
      }
      this.checkSource()
    } finally {
      this.loading = false
    }
  },
}
</script>

<style lang="scss">
.fortune-wheel-sector-form {
  &__image-container {
    display: flex;
    & .ant-upload {
      width: 166px !important;
      height: 166px !important;
      margin: 0 !important;
    }
  }
}
.source-link {
  min-width: 665px;
  transition: all 1s ease;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}
.ant-tabs-nav .ant-tabs-tab {
  margin-right: 2px !important;
}

.select-with-search {
  .ant-select-selection-selected-value {
    width: 100%;
    padding-right: 15px;
  }
}
</style>
