function getTextWidth(text, font) {
  const span = document.createElement('span')
  span.style.cssText = `font: ${font}; visibility: hidden; white-space: nowrap;`
  span.textContent = text
  document.body.appendChild(span)
  const width = span.offsetWidth
  document.body.removeChild(span)
  return width
}

const measureTextCanvas = (text, font) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context.font = font
  return context.measureText(text).width
}

export { getTextWidth, measureTextCanvas }
